<template>
  <div class="bodycont">
    <comHeader />
    <div class="aboutbanner"></div>
    <div class="indexMinker indexMinkerSd" style="padding: 25px 0;">
    <div class="cont w1146">
      <div class="dic_inner">
        <div class="aboutNav dic_flex">
          <a href="#" :class="[{'on':on==1}]" rel="nofollow" @click="change(1)">关于迪科</a>
          <a href="#" :class="[{'on':on==2}]" rel="nofollow" @click="change(2)">发展历程</a>
          <a href="#" :class="[{'on':on==3}]" rel="nofollow" @click="change(3)">核心技术</a>
          <a href="#" :class="[{'on':on==4}]" rel="nofollow" @click="change(4)">合作伙伴</a>
          <a href="#" :class="[{'on':on==5}]" rel="nofollow" @click="change(5)">招聘英才</a>
          <a href="#" :class="[{'on':on==6}]" rel="nofollow" @click="change(6)">联系我们</a>
        </div>
      <div :class="['contsbox',{'active':on==1}]">
        <div class="atitle">关于迪科</div>
        <div class="dic_flex flx_align">
          <div class="apbox">
            <p>迪科巨图由武汉公司、深圳公司组成，武汉迪科巨图科技有限公司，坐落于武汉东湖高新区光谷金融港；深圳市迪科巨图科技有限公司位于深圳龙岗，是国内领先的智慧城市应用、电子政务、大数据、信息服务、电能量采集与管理、电力营销管理及电力自助服务终端系统供应商。武汉迪科巨图科技有限公司于2014 年12 月26 日成立，注册资本1250万元，公司办公面积为626.98平方；深圳市迪科巨图科技有限公司成立于1997年05月13日。公司在2015-2023年获得44个软件著作权及ISO14001、ISO9001 证书，2018 年申请高新技术企业认定和双软企业认定。</p>
            <p>公司核心团队逾15人，其中专业技术人员占比超过80%，多年来始终坚持原始创新、独立研发，在行政执法及监督、治安要素管控、综治考评、交通执法、大数据、智能电网等智慧城市领域有着20 多年的从业经验和行业积累。公司成立以来，武汉公司核心团队深度参与行政执法监督云平台建设项目，公安部PGIS项目、国家电网EPGIS项目、国家测绘地理信息局天地图等国家级项目。公司独立承接湖北省委省情库、湖北省发改委大数据中心、武汉市行政执法监督云平台、当阳雪亮工程、恩施市教育局一键视频报警、武汉智慧平安小区建设等一系列省市重点项目，2018年公司将一键视频报警、智慧平安小区、治安要素、综治考评、交警小程序为公司战略发展行业，研发的“安身保全”系列产品2018年通过公安部检测认定。公司技术服务处于国内领先水平，行业案例丰富，客户遍布全国。公司主营业务为数字司法、数字公安、数字电网、交通执法、行政执法、电子控制系统、电子计算机软件、网络技术的技术开发。</p>
          </div>
          <div class="aimage">
            <img src="../../assets/images/about/Ps123.png" alt=""> 
          </div>
        </div>
    </div>
     <div :class="['contsbox',{'active':on==2}]"><fzlc /></div>
     <div :class="['contsbox',{'active':on==3}]"></div>
     <div :class="['contsbox',{'active':on==4}]"><hzhb /></div>
     <div :class="['contsbox',{'active':on==5}]"><zpyc /></div>
     <div :class="['contsbox',{'active':on==6}]">
         <div class="dic_flex flx_align">
           <div class="cpy_box">
              <div class="dd"> <img src="../../assets/images/about/wh.png" alt=""> </div>
              <div class="dt">武汉</div>
              <div class="dt ds">地址:武汉市光谷金融港B26栋11层1101-1102</div>
              <div class="dt ds">电话:17762477625</div>
              <div class="dt ds">邮箱:dicpsi@163.com</div>
           </div>
            <div class="cpy_box">
              <div class="dd"><img src="../../assets/images/about/sz.png" alt=""> </div>
              <div class="dt">深圳</div>
              <div class="dt ds">地址:深圳市龙岗区龙城街道爱联社区星河时代B9栋2101</div>
              <div class="dt ds">电话:0755-83448830</div>
              <div class="dt ds">邮箱:dicpsi@163.com</div>
           </div>
        </div>

     </div>
    </div>
    </div>
</div>
   <indexFoot />
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import classifyType from '@/components/classify.vue'
  import fzlc from '@/components/about/fzlc.vue'
  import hzhb from '@/components/about/hzhb.vue'
  import zpyc from '@/components/about/zpyc.vue'
  import navTitle from '@/components/navtitle.vue'
  import indexFoot from '@/components/indexfoot.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      classifyType,
      navTitle,
      indexFoot,
      fzlc,
      hzhb,
      zpyc
    },
    data() {
      return {
          on:2
      }
    },
    created() {

    },
    mounted() {

    },
    methods:{
      change(item){
        this.on = item
      }
    }
  }
</script>
<style lang="less" scoped>
.bodycont{
  // background:#f5f7fa;
}
  .aboutbanner{
    width:100%;
    height:300px;
    background: url(../../assets/images/about/about.png) no-repeat center center;
    background-size: cover;
  }
  .aboutNav{
    margin-top:20px;
    justify-content: space-around;
    align-items: center;
    height:44px;
  }
  .contsbox{
    padding:40px 0;
    display: none;
    min-height: 400px;
  }
  .contsbox.active{
    display: block;
  }
  .atitle{
    text-align: center;
    font-size: 28px;
  }
  p{
    line-height: 28px;
    text-indent: 2em;
    margin-top:20px;
    color:#666;
    text-align: justify;
    font-size: 16px;
  }
  .flx_align{
    align-items: center;
    justify-content: space-around;
  }
  .apbox{
    margin-right:20px;
    width:700px;
  }
  .atitle{
    margin-bottom:40px;
    margin-top:60px;
  }
  .on{
    color: #eb6100 !important;
  }
  .dd{
    width:500px;
    height:286px;
    overflow:hidden;
  }
  .dd img{
    width:100%;
    height:auto;
  }
  .dt{
    margin-top:30px;
    font-size:18px;
  }
  .ds{
    font-size: 16px;
    margin-top:15px;
  }
</style>
