<template>
    <!--关于我们--发展历程-->
    <div class="fzlc_box">
        <div class="atitle">发展历程</div>
        <el-timeline>
            <el-timeline-item 
             v-for="(item,index) in fzlcList" 
             :key="index" 
             :timestamp="item.date"
              placement="top" 
              color='#0bbd87'
              >
                <el-card>
                    <!-- <h4>更新 Github 模板</h4> -->
                    <p>{{ item.content }}</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>

    </div>

</template>

<script>
import api from '@/config/api'
import {
    getRequst,
    postRequest
} from '@/api/index'
export default {

    data() {
        return {
            fzlcList: [],
        }
    },
    created() {
        this.fzlcList=this.getList();
    },
    mounted() {
        
    },
    methods: {
        getList() {
           let data=[
              {
                date:'2014年',
                content:'武汉迪科巨图科技有限公司成立，注册资本1250万元。'
              },
              {
                date:'2015年',
                content:'独立研究开发华枫内外网调度系统、湖北省高速公路行车地图绘制、家岭管理区网格化社会管理应用信息平台等。'
              },
              {
                date:'2016年',
                content:'独立研究开发公安部PGIS项目、国家电网EPGIS项目、国家测绘地理信息局天地图等国家级项目、京山县新市镇“平安乡村”项目试点工程项目、地理信息相关库及智能规划分析系统项目、武汉智慧旅游团队出行服务系统等。'
              },
              {
                date:'2017年',
                content:'独立研究开发武汉市行政执法监督云平台、港航海事资源整合等。通过15个软件著作权。获得武汉市智慧城市建设示范项目第二名。'
              },
              {
                date:'2018年',
                content:'独立研究开发当阳市“雪亮工程”云平台、屈家岭管理区农村网格化指挥中心平台软件升级。通过国家高新技术企业认定、双软企业认定、ISO9001、ISO14001、ISO20000认证、1个软件著作权。获得楚商优秀企业，武汉市科技小巨人企业，入选第十一批3551光谷创业人才计划。'
              },
              {
                date:'2019年',
                content:'独立研究开发湖北省级党政机关电子公文系统、一键报警系统、智慧平安小区平台。通过ISO2000认证、公安部检测报告、4个软件著作权。获得武汉市大数据示范项目，楚商理事单位。'
              },
              {
                date:'2020年',
                content:'独立研究开发京山市智慧警务1+N项目。通过4个软件著作权。'
              },
              {
                date:'2021年',
                content:'通过AAA级信用企业、6个软件著作权。'
              },
              {
                date:'2022年',
                content:'独立研究开发高层建筑用电安全排查及地址效验项目。'
              },
              {
                date:'2023年',
                content:'独立研究开发治安要素智能管控平台、交通管理递进式执法平台、平安建设目标管理督导考评系统、临时住址平台、舆情管控平台。通过12个软件著作权。'
              },
              {
                date:'2024年',
                content:'独立研究开发基础管控平台。通过1个软件著作权，累计通过39个软件著作权。'
              },
           ]
           return data;
        },
    }
}
</script>
<style lang="less" scoped>
.fzlc_box{
    width:70%;
    margin:auto;
}
.fzlc_box /deep/ .el-timeline-item__timestamp{
    font-size:18px;
    color:#666;
}
.atitle {
    text-align: center;
    font-size: 28px;
    margin-bottom: 40px;
    margin-top: 60px;
}

p {
    line-height: 28px;
    text-indent: 2em;
    // margin-top: 20px;
    color: #666;
    text-align: justify;
    font-size: 16px;
}

.flx_align {
    align-items: center;
    justify-content: space-around;
}

.apbox {
    margin-right: 20px;
    width: 700px;
}
</style>