<template>
    <!--关于我们--发展历程-->
    <div class="hzhb_box">
        <div class="atitle">合作伙伴</div>
        <div class="hzhb-table-box">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="table-title">表 1　上游交易对手</div>
                    <el-table 
                       :data="tableData1" 
                        border 
                        style="width: 100%">
                        <el-table-column prop="dsName" label="上游交易对手名称" >
                        </el-table-column>
                        <el-table-column prop="cgsp" label="采购商品" width="280">
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="24">
                    <div class="disc">公司与各大系统集成商具备良好合作关系：</div>
                    <div class="table-title">表 2　下游交易对手</div>
                    <el-table :data="tableData2" 
                        border 
                        style="width: 100%">
                        <el-table-column prop="dsName" label="上游交易对手名称" >
                        </el-table-column>
                        <el-table-column prop="cgsp" label="采购商品" width="280">
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>

        </div>

    </div>

</template>

<script>
import api from '@/config/api'
import {
    getRequst,
    postRequest
} from '@/api/index'
export default {

    data() {
        return {
            tableData1: [],
            tableData2: [],
        }
    },
    created() {
        this.tableData1 = this.getList1();
        this.tableData2 = this.getList2();
    },
    mounted() {

    },
    methods: {
        getList1() {
            let data = [
                {
                    dsName: '北京超图软件股份有限公司',
                    cgsp: 'GIS平台'
                },
                {
                    dsName: '中金数据（武汉）超算技术有限公司',
                    cgsp: '数据库'
                },
                {
                    dsName: '杭州海康威视数字技术股份有限公司武汉分公司',
                    cgsp: '视频产品'
                },
                {
                    dsName: '深圳艾迪泰格科技有限公司',
                    cgsp: '道闸'
                },
                {
                    dsName: '深圳云天励飞技术有限公司',
                    cgsp: '视频产品'
                },
                {
                    dsName: '智慧云天（深圳）技术有限公司',
                    cgsp: '门禁'
                },
                {
                    dsName: '深圳市联信永达科技有限公司',
                    cgsp: '服务器'
                },
                {
                    dsName: '武汉名航鑫世纪科技有限公司',
                    cgsp: '服务器'
                },
                {
                    dsName: '浙江海康科技有限公司',
                    cgsp: '无线终端'
                },
              

            ]
            return data;
        },

        getList2() {
            let data = [
                {
                    dsName: '武汉烽火信息集成技术有限公司',
                    cgsp: '软件技术服务'
                },
                {
                    dsName: '恩施市教育局',
                    cgsp: '软件技术服务'
                },
                {
                    dsName: '中国移动通信集团湖北有限公司（荆门）分公司',
                    cgsp: '软件技术服务'
                },
                {
                    dsName: '郧西县司法局',
                    cgsp: '软件技术服务'
                },
                {
                    dsName: '河北极尔科技有限公司',
                    cgsp: '软件技术服务'
                },
                {
                    dsName: '中建电子工程有限公司',
                    cgsp: '软件技术服务'
                },
                {
                    dsName: '湖北华中电力科技开发有限责任公司',
                    cgsp: '软件技术服务'
                },
                {
                    dsName: '湖北省楚天视讯网络有限公司恩施分公司',
                    cgsp: '集成服务'
                },
                {
                    dsName: '中国电信股份有限公司荆州分公司',
                    cgsp: '集成服务'
                },
                {
                    dsName: '珠海华伟电气科技股份有限公司',
                    cgsp: '集成服务'
                },
                {
                    dsName: '江苏杰城信息科技有限公司',
                    cgsp: '集成服务'
                },

            ]
            return data;
        },
    }
}
</script>
<style lang="less" scoped>
.hzhb_box {
    width: 80%;
    margin: auto;
}
.table-title{
    margin:10px;
    text-align: center;
    // color:#000;
    font-size:16px;
}
.disc{
    margin-top:100px;
}

.hzhb-table-box /deep/ .el-table{
   font-size:16px;
} 
.hzhb-table-box /deep/ .el-table th{
    color: #444;
    font-size: 18px;
}

.atitle {
    text-align: center;
    font-size: 28px;
    margin-bottom: 40px;
    margin-top: 60px;
}

p {
    line-height: 28px;
    text-indent: 2em;
    // margin-top: 20px;
    color: #666;
    text-align: justify;
    font-size: 16px;
}

.flx_align {
    align-items: center;
    justify-content: space-around;
}

.apbox {
    margin-right: 20px;
    width: 700px;
}
</style>