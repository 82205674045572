<template>
    <!--关于我们--发展历程-->
    <div class="hzhb_box">
        <!-- <div class="atitle">招聘英才</div> -->
        <div class="zpp-box">
            <div class="apbox" v-for="(item, index) in list" :key="index">
                <h3 class="zptitle">{{ item.title }}</h3>
                <template v-if="item.zwms && item.zwms.length">
                    <p class="item-title">职位描述:</p>
                    <p v-for="(zwms, ind) in item.zwms">{{ ind + 1 }}、{{ zwms }}</p>
                </template>
                <template v-if="item.gwzz && item.gwzz.length">
                    <p class="item-title">岗位职责:</p>
                    <p v-for="(gwzz, ind) in item.gwzz">{{ ind + 1 }}、{{ gwzz }}</p>
                </template>
                <template v-if="item.gwyq && item.gwyq.length">
                    <p class="item-title">岗位要求:</p>
                    <p v-for="(gwyq, ind) in item.gwyq">{{ ind + 1 }}、{{ gwyq }}</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/config/api'
import {
    getRequst,
    postRequest
} from '@/api/index'
export default {

    data() {
        return {
            list: [],
        }
    },
    created() {
        this.list = this.getList();
    },
    mounted() {

    },
    methods: {
        getList() {
            let data = [
                {
                    title: 'Java开发工程师',
                    //岗位职责
                    gwzz: [
                        '参与系统需求分析、架构设计和概要设计；',
                        '负责系统详细设计、代码实现以及相关文档编写；',
                        '负责程序架构设计开发等；',
                        '负责解决开发过程中的技术难点问题；',
                        '理解需求人员编写的需求文档，并进行数据结构、组件和程序等的设计工作。'
                    ],
                    gwyq: [
                        '本科及以上学历，计算机科学技术、软件、应用等相关专业，5年及以上专业相关工作经验；',
                        '有扎实的计算机软件，或计算机应用专业知识及技能；',
                        '具有较强的学习、分析问题解决问题的能力，能够快速学习掌握开发语言和工具；',
                        '有远大志向，虚心好学，有良好的团队协作精神，较好的沟通能力；',
                        '熟练掌握Java语言，有扎实的Java知识基础；',
                        '熟练掌握springboot框架，有springcloud实际开发经验，熟练使用shiro、mybatisplus、 activity5、mq等；',
                        '熟练掌握Javascript、css、dwr、Hibernate、spring、webwork、redis、mongodb等技术；',
                        '熟练掌握Oracle /Mysql/Sqlserver中一种数据库，熟练使用SQL；',
                        '熟悉各种设计模式，熟练linux系统操作；',
                        '熟悉C#、C++编程者优先；',
                        '待遇：面议。'
                    ],
                    //任职资格
                    yzzg: [
                        '具有5年及以上Java开发经验，计算机相关专业；',
                        '具备良好的面向对象编程经验，具有较强的系统设计及架构设计能力；',
                        '熟练HTML/CSS/Javascript/JQuery等前端开发；',
                        '熟练掌握MySQL，Oracle，SQL，存储过程编写等；',
                        '熟悉SpringMVC、Springboot，MyBatis，Hibernate等Java框架；',
                        '熟悉大数据hadoop，spark等技术；',
                        '有大项目或丰富项目实施经验；',
                        '具有较强的学习能力，积极上进，有较强的工作责任心和事业心。',
                    ],
                },
                {
                    title: 'Cesium开发工程师',
                    //岗位职责
                    gwzz: [],
                    //岗位要求
                    gwyq: [
                        '地理信息系统(GIS)、计算机、遥感、测绘相关专业，本科及以上学历；',
                        '学习能力强，责任心强，具有团队合作精神和良好的沟通能力；',
                        '精通Cesium、WebGL开发，具有计算机图形学基础；',
                        '加分项:熟悉ThreeJS、Leaflet、OpenLayers等GIS引擎优先;',
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [
                        '基于开源软件Cesium，融合GIS信息，从事数据可视化相关开发工作；',
                        '研究和处理海量三维数据调度和渲染等功能，优化系统渲染性能；',
                        '参与公司核心产品前端基础功能开发与接口封装；',
                        '能独立查找和解决开发中遇到的问题，优化前端用户体验，同时能够指导初级开发人员并协助其解决开发中遇到的问题任职要求。',
                    ],

                },
                {
                    title: 'Web前端开发工程师',
                    //岗位职责
                    gwzz: [
                        '承担WEB前端核心模块的设计、开发工作，对代码质量及进度负责；',
                        '对公司的产品提出用户界面和用户体验方面的合理建议；',
                        '实现网站页面特效、JS效果;维护及优化前端页面性能，优化交互体验；',
                        '解决主流浏览器的兼容性问题，页面制作符合seo规范；',

                    ],
                    //岗位要求
                    gwyq: [
                        '熟练掌握JavaScript语言,能够脱离各JS类库后熟练的进行跨浏览器开发；',
                        '熟悉各种Web前端技术,包括HTML/XML/CSS等,具有前后端分离的应用开发经验；',
                        '熟练掌握Node.js并有能力基于Node.js设计及开发相关工具及平台；',
                        '关注前端发展，熟练掌握W3C关于HTML、CSS的相关标准;熟练掌握echart、canvas;熟练掌握Ecmascript相关标准；',
                        '能够深入理解Anglur/React/VUE等前端框架，并能够独立完成项目架构；',
                        '对Web下层的HTTP协议、TCP/IP、DNS等熟练掌握，并能够利用相关知识解决项目问题；',
                        '同时会UI设计优先考虑。',
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [],
                },
                {
                    title: '系统集成项目经理',
                    //岗位职责
                    gwzz: [
                        '负责公司物联网、智慧政务、智慧城管、智慧社管等项目技术方案的设计编写、标书准备、用户答疑等工作；',
                        '负责公司项目实施规划，过程管理，交付运维等工作；',
                        '负责合作伙伴及渠道商的培训支持。',
                    ],
                    //岗位要求
                    gwyq: [
                        '35岁以下，本科及以上学历，网络、通信、电子、自动化或计算机相关专业，5年以上系统集成工作经验；',
                        '熟悉IT基础软硬件相关专业技术及产品，熟悉主流软硬件系统品牌及基本价格；',
                        '具备较高的专业技术水平，能独立处理本专业技术问题，包含综合布线、网络通讯、机房工程、视频监控、门禁、报警、公共广播、一卡通、大屏显示、指挥中心建设等；',
                        '具有投标预算的造价能力；',
                        '丰富的语言表达和沟通能力，较强的团队合作精神，善于组织协调和学习提高；',
                        '熟练使用Office、Photoshop等办公软件；',
                        '吃苦耐劳，工作积极主动，能够接受工作挑战，能适应经常出差。',
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [],
                },
                {
                    title: '实施工程师',
                    //岗位职责
                    gwzz: [
                        '保质保量地组织、实施公司下达的工程任务；',
                        '监控工程实施过程；',
                        '做好工程实施的安全防护工作；',
                        '及时发现、解决工程实施过程中的问题；',
                        '工程实施中维护好客户关系；',
                        '负责公司网络及办公设备购置、维护；',
                        '项目硬件产品及公司办公设备采购；',
                        '协助开发、技术服务及市场部等部门的工作；',
                        '负责收集整理、反馈客户需求，为产品开发和完善、市场开拓提供参考；',
                        '公司交办的其他工作。',
                    ],
                    //岗位要求
                    gwyq: [
                        '计算机、信息、电子或相关专业专科及以上学历；',
                        '具有电子设备的安装调试能力或经验；',
                        '具备一定的网络通信基础知识；',
                        '有一定的java基础的优先；',
                        '能接受出差',
                        '年龄在20－35之间',
                        '具有良好的沟通能力和一定的抗压能力。',
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [],
                },
                {
                    title: '售前经理',
                    //岗位职责
                    gwzz: [
                        '辅助商务完成项目前期沟通和交流，充分理解并自能引导挖掘客户需求，完成需求分析；',
                        '能独立编写相关方案，包括但不限于产品或项目介绍、解决方案和投标方案；',
                        '配合业主单位进行项目立项、招投标流程工作；',
                        '根据招标要求，负责组织相关人员进行投标准备工作，并承担技术文件的编写及标书讲解；',
                        '根据公司战略发展方向及业务需求，进行软硬件厂商的技术沟通；',
                        '项目实施前负责向实施部门进行项目交底，并协助实施部门进行需求文档的确认工作，参与实施方案的评审；',
                        '负责产品和案例的整理、分析、总结和共享，对行业、市场、用户需求、竞争对手等方面定期分析，为公司的市场方向、产品研发和软件开发等提供建议。',
                    ],
                    //岗位要求
                    gwyq: [
                        '通信、电子、计算机等相关专业，本科及以上学历，有2年软件开发经验，3年以上售前经验，有公安、政法等售前经验者优先；',
                        '具有良好的语言表达和沟通协调能力，良好的信息收集分析及文字表达能力；',
                        '具有较强的客户服务意识，工作认真细致，作风严谨；',
                        '具有良好的PPT、文档编写能力；',
                        '具有强烈的事业心和进取心，工作积极主动，有良好的敬业精神和团队精神',
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [],
                },
                {
                    title: '销售经理',
                    //岗位职责
                    gwzz: [
                        '广泛收集市场信息，形成独立的市场分析',
                        '负责项目销售，与客户洽谈业务，签订合同，跟进项目实施',
                        '负责项目货款及时足额回笼',
                        '参与项目售后的质量跟踪，跟进售后服务完成情况',
                        '执行公司品牌和产品的推介宣传，完成公司统一布署的促销任务',
                        '完成每周每月的工作报告',
                        '完成上级交办的其它任务'
                    ],
                    //岗位要求
                    gwyq: [
                        '35岁以下，大专及以上学历，市场营销或计算机相关专业，2年以上相关工作经验，能适应经常出差',
                        '形象端正、谈吐大方，具有良好的亲和力，善于沟通',
                        '熟悉智慧城市或电力行业业务，对智慧城市或电力行业基本知识较为了解，有智慧城市或电力行业销售经验，志愿在该行业上发展',
                        '忠诚，具有良好团队合作精神，行动力强，坚韧不拔，希望挑战自我，做事有激情',
                        '善于挖掘客户，有较强的学习能力、综合分析能力及反馈意识',
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [],
                },
                {
                    title: '财务主管',
                    //岗位职责
                    gwzz: [
                        '按要求对各项开支进行核算，对各种原始凭证进行审核，根据审核无误的原始凭证，按照会计制度，及时编制记账凭证。做到账证、账实相符',
                        '做好记账、结账和对账，并及时完成档案装订、整理、归档工作',
                        '编制公司各项报表，包括财务报表、资金计划、增值税、项目成本等',
                        '按期完成税务、统计、火炬等月度年度报表的申报工作',
                        '做好往来帐款的管理。对各往来款项，要严格审核其真实性，并按收付单位、个人设置明细账。严格执行结算纪律，及时清理债权债务',
                        '负责公司研发加计扣除、高新技术企业、科技型中小企业、双软企业等资质申请相关工作',
                        '为公司预算编制及管理提供财务数据，负责编制年度财务预算及日常预算分析',
                        '对公司税收进行整体筹划与管理，按时完成税务申报以及年终汇算工作',
                        '负责公司各项固定资产、无形资产的登记、核对，按规定计提折旧，建立固定资产、无形资产台账',
                        '完成上级交付的临时工作及其他任务'
                    ],
                    //岗位要求
                    gwyq: [
                        '教育背景：具有本科以上学历，会计、财务管理等相关专业',
                        '工作经验：负责公司全盘账务3年以上',
                        '资格及职称：中级会计师职称以上优先',
                        '专业技能：熟悉企业会计准则、各项财经和税收法律法规、以及相关财务事务处理方法;熟练运用财务软件以及OFFICE等办公软件',
                        '职位能力与素质：责任心强，知识素养高，有较强的沟通协调能力;有良好的纪律性、团队合作以及开拓创新精神',
                        '有高新技术企业、软件企业会计工作经历者优先'
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [],
                },
                {
                    title: '行政人事文员',
                    //岗位职责
                    gwzz: [
                        '女性，身高155CM及以上，年龄35岁及以下，形象端庄；',
                        '大专及以上学历，要求财务、行政管理或人事管理相关专业；',
                        '具备财务知识，有会计、出纳相关经验者优先；',
                        '二年及以上相关工作经验；',
                    ],
                    //岗位要求
                    gwyq: [
                        '接听、转接总机电话；',
                        '日常来人来访的接待',
                        '机票、火车票、宾馆、酒店的预订；',
                        '传真、邮件、包裹的收发；',
                        '文件档案资料的整理、校核、复印、保管等管理工作；',
                        '办理企业年审等办证工作；',
                        '办公用品的采购、保管及发放；',
                        '处理公司人事方面的事务；',
                        '负责公司员工的日常考勤；',
                        '出纳工作；',
                        '协助会计办理税务申报工作；',
                        '协助公司商务接待工作等。'
                    ],
                    //任职资格
                    yzzg: [],
                    //职位描述
                    zwms: [],
                },
            ]
            return data;
        },

    }
}
</script>
<style lang="less" scoped>
.hzhb_box {
    width: 90%;
    margin: auto;
}

.item-title{
    margin: 10px 0 0 0;
    // text-align: center;
    color:#444;
    // font-size: 16px;
}



p {
    line-height: 28px;
    // text-indent: 2em;
    color: #666;
    text-align: justify;
    font-size: 16px;
}

.flx_align {
    align-items: center;
    justify-content: space-around;
}

.apbox {
    margin: 0 auto;
    margin-bottom: 40px;
    padding-left: 300px;
}

.zptitle {
    margin-bottom: 20px;
}

.atitle {
    margin-bottom: 40px;
    margin-top: 60px;
}
</style>